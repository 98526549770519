import { styled } from "utils/emotion";
import media from "utils/mediaqueries";

export const SectionTextStyled = styled("section")(
  (props) => ({
    position: "relative",
    padding: "24px 0",
    zIndex: 1,
    [media("tablet")]: {
      padding: "70px 0",
    },
    background: props.theme.sectionTextBackground,
  }),
  (props) =>
    props.sectionPadding === "large" && {
      padding: "65px 0 35px",
      [media("tablet")]: {
        padding: "130px 0 70px",
      },
    },
  (props) =>
    props.listStyleImage && {
      ul: {
        listStyleImage: `url(${props.listStyleImage})`,
        listStylePosition: "outside",
        paddingLeft: 15,
      },

      li: {
        marginBottom: 10,
      },
    }
);

export const TextContentWrapStyled = styled("div")({}, (props) => ({
  whiteSpace: "pre-line",
  textAlign: props.layout === "right" ? "left" : props.layout,

  [media("tabletFluid")]: {
    textAlign: props.layout,
  },
}));

export const ButtonsWrapStyled = styled("div")({}, (props) => ({
  margin: props.margin,
  textAlign: props.layout === "right" ? "left" : props.layout,

  [media("tabletFluid")]: {
    textAlign: props.layout,
  },
}));

export const TextStyled = styled("div")({}, (props) => ({
  margin: props.margin,
  maxWidth: "none",

  [media("tabletFluid")]: {
    marginLeft: props.layout === "left" ? "0" : "auto",
    marginRight: props.layout === "right" ? "0" : "auto",
  },

  [media("laptop")]: {
    maxWidth: props.maxWidth || "none",
  },

  // p: {
  // margin: 0,
  // },
}));
