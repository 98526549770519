import React from "react";
import { graphql } from "gatsby";

import SwitchTheme from "components/SwitchTheme";

import TextDefault from "./TextDefault";
import TextColumns from "./TextColumns";

import { SectionTextStyled } from "./styles";

export default function Text({ pageBuilder, className }) {
  const {
    headline,
    headlineTag,
    textSectionText,
    textSectionLayout,
    textSectionPadding,
    textSectionTheme,
    textSectionButtons,
    textFullWidth,
    bulletImage,
    titleFont,
  } = pageBuilder;

  const text = textSectionText?.content;
  const listStyleImage = bulletImage?.[0]?.url;

  return (
    <SwitchTheme name={textSectionTheme}>
      <SectionTextStyled
        sectionPadding={textSectionPadding}
        className={className}
        listStyleImage={listStyleImage}
      >
        {textSectionLayout === "columns" ? (
          <TextColumns
            layout={textSectionLayout}
            text={text}
            headline={headline}
            titleFont={titleFont}
            headlineTag={headlineTag}
            buttons={textSectionButtons}
          />
        ) : (
          <TextDefault
            layout={textSectionLayout}
            text={text}
            titleFont={titleFont}
            headline={headline}
            headlineTag={headlineTag}
            buttons={textSectionButtons}
            textFullWidth={textFullWidth}
          />
        )}
      </SectionTextStyled>
    </SwitchTheme>
  );
}

export const query = graphql`
  fragment PageBuilderTextQuery on Craft_PageBuilderText {
    id
    headline: textSectionHeadline
    headlineTag: textSectionHeadlineTag
    textSectionText {
      content
    }
    titleFont
    textSectionTheme
    textSectionLayout
    textSectionPadding
    textFullWidth
    bulletImage {
      ...CraftImageFluid
    }
    textSectionButtons {
      id
      button {
        text
        url
        entry {
          fullUri
        }
      }
      textSectionButtonTheme
      sectionTextButtonStripped
    }
  }
`;
