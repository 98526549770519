import React from 'react'

import Headline from 'components/Headline'
import Button from 'components/Button'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import Redactor from 'components/Redactor'
import Fade from 'react-reveal/Fade'

import { parseAsText } from 'utils/parser'

import { TextContentWrapStyled, TextStyled, ButtonsWrapStyled } from './styles'

export default function TextDefault({
  text,
  headline,
  headlineTag,
  buttons,
  layout,
  textFullWidth,
  titleFont
}) {
  return (
    <Grid>
      <GridItem mobile={1} tabletFluid={1}>
        <TextContentWrapStyled layout={layout}>
          {headline && (
            <Fade bottom distance="10px" delay={200}>
              <Headline
                margin={layout == 'center' ? '0 auto 30px auto' : '0 0 30px'}
                element={headlineTag}
                maxWidth={!textFullWidth && '80%'}
                titleFont={titleFont}>
                {parseAsText(headline)}
              </Headline>
            </Fade>
          )}
          {text && (
            <Fade bottom distance="10px" delay={400}>
              <TextStyled
                margin="0"
                layout={layout}
                maxWidth={!textFullWidth && '80%'}>
                <Redactor content={text} parseType="content" />
              </TextStyled>
            </Fade>
          )}
        </TextContentWrapStyled>
        <ButtonsWrapStyled layout={layout} margin={text && '40px 0 0'}>
          {buttons.map((button) => (
            <div key={button.id}>
              <Fade left distance="10px" delay={600}>
                <Button
                  to={button.button?.entry?.fullUri || button.button?.url}
                  themeName={button?.textSectionButtonTheme}
                  stripped={button?.sectionTextButtonStripped}
                  icon="arrowRight"
                  margin="15px 0">
                  {button.button?.text}
                </Button>
              </Fade>
            </div>
          ))}
        </ButtonsWrapStyled>
      </GridItem>
    </Grid>
  )
}
