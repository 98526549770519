import React from 'react'

import Headline from 'components/Headline'
import Button from 'components/Button'
import Grid from 'components/Grid'
import GridItem from 'components/GridItem'
import Redactor from 'components/Redactor'
import Fade from 'react-reveal/Fade'

import { parseAsText } from 'utils/parser'

import { TextStyled } from './styles'

export default function TextColumns({ text, headline, headlineTag, buttons,titleFont }) {
  return (
    <Grid>
      <GridItem mobile={1} laptop={1 / 2}>
        {headline && (
          <Fade bottom distance="10px" delay={200}>
            <Headline margin="0 0 30px" element={headlineTag} titleFont={titleFont}>
              {parseAsText(headline)}
            </Headline>
          </Fade>
        )}
      </GridItem>
      <GridItem mobile={1} laptop={1 / 2}>
        {text && (
          <Fade bottom distance="10px" delay={400}>
            <TextStyled margin="0 0 20px" layout="left">
              <Redactor content={text} parseType="content" />
            </TextStyled>
          </Fade>
        )}
        {buttons.map((button) => (
          <Fade left distance="10px" delay={600}>
            <Button
              key={button.id}
              to={button.button?.entry?.fullUri || button.button?.url}
              themeName={button?.textSectionButtonTheme}
              stripped={button?.sectionTextButtonStripped}
              icon="arrowRight"
              margin="20px 0 0">
              {button.button?.text}
            </Button>
          </Fade>
        ))}
      </GridItem>
    </Grid>
  )
}
